<template>
  <layout-landing>
    <router-view />
  </layout-landing>
</template>

<script>
    
import LayoutLanding from '@core/layouts/layout-landing/LayoutLanding.vue'

export default {
  components: {
    LayoutLanding,
  },
}
</script>


